import styled from "styled-components";

export const StyledBoxes = styled.div`
  display: flex;
  gap: 15px;
  transform: translateY(-50%);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    transform: translateY(-15%);
  }
`;

export const Box = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.blue};
  padding: 25px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.third};
  font-weight: 100;
`;
export const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.font.third};
  margin-top: 15px;
  font-weight: 100;
`;
