import { useSelector } from "react-redux";
import {
  Item,
  Split,
  StyledFooter,
  Main,
  Sub,
  Link,
  Text,
} from "./Footer.Styled";
import { Container } from "@think-internet/ui-components";
import props from "../../../redux/props";

const Footer = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledFooter>
      <Main>
        <Container>
          <Split>
            <Item>
              <Text>{translation.footer.name}</Text>
              <Text>{translation.footer.street}</Text>
              <Text>{translation.footer.city}</Text>
            </Item>
            <Item>
              <Text>{translation.footer.openHours}</Text>
              <Text>
                <Link href={`tel:${translation.footer.phone}`}>
                  {translation.footer.phonePrefix}
                  {translation.footer.phone}
                </Link>
              </Text>
              <Text>
                {translation.footer.faxPrefix}
                {translation.footer.phone}
              </Text>
              <Text>
                <Link
                  href={`https://${translation.footer.homepage}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {translation.footer.homepage}
                </Link>
              </Text>
            </Item>
            <Item>
              <Text>{translation.footer.description}</Text>
            </Item>
          </Split>
        </Container>
      </Main>
      <Sub>
        <Container>
          <Split>
            <Item>
              <Link
                href={"https://www.wilms.eu/datenschutzerklaerung/"}
                target="_blank"
                rel="noreferrer"
                small
              >
                {translation.dataProtection.headline}
              </Link>
              {" | "}
              <Link
                href={"https://www.wilms.eu/impressum/"}
                target="_blank"
                rel="noreferrer"
                small
              >
                {translation.imprint.headline}
              </Link>
            </Item>
          </Split>
        </Container>
      </Sub>
    </StyledFooter>
  );
};

export default Footer;
