import { Container } from "@think-internet/ui-components";
import {
  StyledOpenPositions,
  Headline,
  Content,
  Header,
  Position,
  Selection,
  Split,
  Category,
  CategoryContent,
  List,
  Item,
  Plus,
} from "./OpenPositions.Styled";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";
import { useState } from "react";
import ApplyForm from "./ApplyForm/ApplyForm";

const OpenPositions = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const categories = ["tasks", "expectations", "offerings"];
  const [activeIndex, setActiveIndex] = useState(null);

  const activate = (i) => () => setActiveIndex(activeIndex === i ? null : i);

  return (
    <StyledOpenPositions id="open-positions">
      <Container>
        <Headline>{translation.home.openPositions.headline}</Headline>
        <Selection>
          {translation.home.openPositions.positions.map((p, i) => {
            return (
              <Position key={getUUID()}>
                <Header active={activeIndex === i} onClick={activate(i)}>
                  {p.title}
                  <Plus active={activeIndex === i} />
                </Header>
                <Content active={activeIndex === i}>
                  {categories.map((cat) => {
                    return (
                      <Split key={getUUID()}>
                        <Category>
                          {translation.home.openPositions[cat]}
                        </Category>
                        <CategoryContent>
                          {Array.isArray(p[cat]) && (
                            <List>
                              {p[cat].map((i) => (
                                <Item key={getUUID()}>{i}</Item>
                              ))}
                            </List>
                          )}
                          {!Array.isArray(p[cat]) && p[cat]}
                        </CategoryContent>
                      </Split>
                    );
                  })}
                  <ApplyForm title={p.title} />
                </Content>
              </Position>
            );
          })}
        </Selection>
      </Container>
    </StyledOpenPositions>
  );
};

export default OpenPositions;
