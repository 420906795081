import { useState } from "react";
import { useSelector } from "react-redux";
import props from "../../../../redux/props";
import {
  StyledApplyForm,
  Split,
  Side,
  InputWrapper,
  ButtonWrapper,
  CustomInput,
} from "./ApplyForm.Styled";
import DragAndDrop from "./DragAndDrop/DragAndDrop";
import {
  awsPresignedUploadURLLambdaPrefix,
  awsSendMailLambdaPrefix,
  maxFileSizeInBytes,
} from "../../../../utility";
import { callLambda, s3Upload } from "../../../../fetch";
import toast, { TYPE } from "../../../../toast";
import { Button } from "@think-internet/ui-components";

const ApplyForm = ({ title }) => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const [data, setData] = useState({ title });

  const setProp = (prop) => (value) => setData({ ...data, [prop]: value });

  const uploadFiles = async (files) => {
    const outputFile = [];
    for (const file of files) {
      const data = await callLambda(awsPresignedUploadURLLambdaPrefix, {
        name: file.name,
        type: file.type,
      });
      if (data && data.uploadURL && data.key) {
        const status = await s3Upload(data.uploadURL, file, (progress) =>
          console.log(progress)
        );
        if (status) {
          outputFile.push({ key: data.key, name: file.name });
        }
      }
    }
    return outputFile;
  };

  const submit = async (e) => {
    e.preventDefault();
    const payload = { ...data };
    if (payload.files) {
      payload.files = await uploadFiles(payload.files);
    }
    const response = await callLambda(awsSendMailLambdaPrefix, payload);
    if (response && response.status === true) {
      toast(TYPE.SUCCESS, translation.home.openPositions.success.form);
      setData({ title });
    } else {
      toast(TYPE.ERROR, translation.home.openPositions.error.form);
    }
  };

  return (
    <StyledApplyForm>
      <Split onSubmit={submit}>
        <Side>
          <InputWrapper>
            <CustomInput
              value={data.firstname}
              onChange={setProp("firstname")}
              placeholder={translation.generic.firstname + "*"}
              required
            />
            <CustomInput
              value={data.lastname}
              onChange={setProp("lastname")}
              placeholder={translation.generic.lastname + "*"}
              required
            />
            <CustomInput
              type="email"
              value={data.mail}
              onChange={setProp("mail")}
              placeholder={translation.generic.mail + "*"}
              required
            />
            <CustomInput
              value={data.phone}
              onChange={setProp("phone")}
              placeholder={translation.generic.phone + "*"}
              required
            />
            <CustomInput
              value={data.entryDate}
              onChange={setProp("entryDate")}
              placeholder={translation.home.openPositions.form.entryDate}
            />
            <CustomInput
              value={data.salaryExpectation}
              onChange={setProp("salaryExpectation")}
              placeholder={
                translation.home.openPositions.form.salaryExpectation
              }
            />
          </InputWrapper>
        </Side>
        <Side>
          <DragAndDrop
            title={translation.home.openPositions.form.dragAndDropTitle}
            onFileCallback={setProp("files")}
            files={data.files}
            maxFileSizeInBytes={maxFileSizeInBytes}
            maxFileSizeExceededToast={
              translation.home.openPositions.form.maxFileSizeExceededToast
            }
            hint={translation.home.openPositions.form.dragAndDropHint}
          />
        </Side>
        <ButtonWrapper>
          <Button
            type="submit"
            text={translation.home.openPositions.form.cta}
          />
        </ButtonWrapper>
      </Split>
    </StyledApplyForm>
  );
};

export default ApplyForm;
