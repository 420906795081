import { useSelector } from "react-redux";
import { getUUID } from "../../../utility";
import {
  StyledReasons,
  Headline,
  HeadlineNumber,
  Items,
  Item,
  Number,
  Title,
  Text,
} from "./Reasons.Styled";
import props from "../../../redux/props";
import n1 from "../../../assets/image/home/reasons/n1.svg";
import n2 from "../../../assets/image/home/reasons/n2.svg";
import n3 from "../../../assets/image/home/reasons/n3.svg";
import n4 from "../../../assets/image/home/reasons/n4.svg";
import n5 from "../../../assets/image/home/reasons/n5.svg";
import n6 from "../../../assets/image/home/reasons/n6.svg";
import n7 from "../../../assets/image/home/reasons/n7.svg";
import n8 from "../../../assets/image/home/reasons/n8.svg";
import n9 from "../../../assets/image/home/reasons/n9.svg";
import { Container } from "@think-internet/ui-components";

const Reasons = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const numbers = [n1, n2, n3, n4, n5, n6, n7, n8, n9];
  return (
    <StyledReasons>
      <Container>
        <Headline>
          <HeadlineNumber>{"9 "}</HeadlineNumber>
          {translation.home.reasons.headline}
        </Headline>
        <Items>
          {translation.home.reasons.items.map((item, i) => (
            <Item key={getUUID()}>
              <Number src={numbers[i]} />
              <Title>{item.title}</Title>
              <Text>{item.text}</Text>
            </Item>
          ))}
        </Items>
      </Container>
    </StyledReasons>
  );
};

export default Reasons;
