import {
  StyledContact,
  Headline,
  Split,
  Person,
  Item,
  PersonImage,
  Name,
  Title,
  Subtitle,
  Mail,
  Detail,
} from "./Contact.Styled";
import friedrich from "../../../assets/image/home/contact/friedrich.png";
import kathrin from "../../../assets/image/home/contact/kathrin.jpg";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { Container } from "@think-internet/ui-components";
import { getUUID } from "../../../utility";

const Contact = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  const personImages = [friedrich, kathrin];

  return (
    <StyledContact>
      <Container>
        <Headline>{translation.home.contact.headline}</Headline>
        <Split>
          {translation.home.contact.people.map((p, i) => (
            <Person key={getUUID()}>
              <Item justify={"left"}>
                <PersonImage src={personImages[i]} />
              </Item>
              <Item>
                <Detail>
                  <Name>{p.name}</Name>
                  <Title>{p.title}</Title>
                  {p.subtitle && <Subtitle>{p.subtitle}</Subtitle>}
                  <Mail href={`mailto:${p.mail}`}>{p.mail}</Mail>
                </Detail>
              </Item>
            </Person>
          ))}
        </Split>
      </Container>
    </StyledContact>
  );
};

export default Contact;
