import { Input } from "@think-internet/ui-components";
import styled from "styled-components";

export const StyledApplyForm = styled.div`
  width: 80%;
  margin: 25px auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const Split = styled.form`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Side = styled.div`
  width: calc(50% - 10px);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

export const CustomInput = styled(Input)`
  font-size: 16px;
`;
