import styled from "styled-components";
import openedIcon from "../../../assets/image/home/openPositions/opened.png";
import closedIcon from "../../../assets/image/home/openPositions/closed.png";

export const StyledOpenPositions = styled.div`
  margin-top: 100px;
`;

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
`;

export const Selection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 25px;
`;

export const Position = styled.div``;

export const Header = styled.div`
  position: relative;
  background-color: ${({ theme, active }) =>
    active ? theme.color.background.blue : theme.color.background.lightgray};
  color: ${({ theme, active }) =>
    active ? theme.color.font.third : theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  text-transform: uppercase;
  padding: 10px 15px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
    padding: 10px 50px 10px 5px;
  }
`;

export const Content = styled.div`
  padding: 15px;
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-direction: column;
  gap: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 15px 5px;
  }
`;

export const Split = styled.div`
  display: flex;
  gap: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Category = styled.div`
  width: 20%;
  color: ${({ theme }) => theme.color.font.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const CategoryContent = styled.div`
  width: 80%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const List = styled.ul`
  margin-left: 15px;
`;

export const Item = styled.li``;

export const Plus = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  background-image: url(${({ active }) => (active ? openedIcon : closedIcon)});
  background-position: center center;
  background-size: cover;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;
