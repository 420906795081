import Layout from "../Layout/Layout";
import { StyledHome } from "./Home.Styled";
import Hero from "./Hero/Hero";
import Boxes from "./Boxes/Boxes";
import Reasons from "./Reasons/Reasons";
// import Quote from "./Quote/Quote";
import Contact from "./Contact/Contact";
import OpenPositions from "./OpenPositions/OpenPositions";

const Home = () => {
  return (
    <Layout authRequired={false} noPaddingTop={true}>
      <StyledHome>
        <Hero />
        <Boxes />
        <Reasons />
        {/* <Quote /> */}
        <Contact />
        <OpenPositions />
      </StyledHome>
    </Layout>
  );
};

export default Home;
