import { v4 as uuidv4 } from "uuid";
const currencyFormatter = require("currency-formatter");
const prettyBytes = require("pretty-bytes");

export const offlineMode = process.env.NODE_ENV !== "production";

export const backendDomain = offlineMode
  ? "http://localhost:8100"
  : "https://api.wilmscareer.think-internet.de";

export const getUUID = () => uuidv4();

export const recaptchaV3SiteKey = "";

export const getQueryString = (string, requiredString) => {
  if (
    typeof string === "string" &&
    string.length > 0 &&
    typeof requiredString === "string"
  ) {
    string = string.substring(1);
    const split = string.split("=");
    return !string.includes("&") &&
      split.length === 2 &&
      split[0] === requiredString &&
      split[1].length > 0
      ? decodeURIComponent(split[1])
      : false;
  } else {
    return false;
  }
};

export const getPrettyTime = (date) => {
  // date.setMinutes(date.getMinutes() + (date.getTimezoneOffset() * -1))
  const fill = (number) => {
    number = `${number}`;
    return number.length === 1 ? `0${number}` : number;
  };
  const minute = fill(date.getMinutes());
  const hour = fill(date.getHours());
  return `${hour}:${minute}`;
};
export const getPrettyDateTime = (date) => {
  // date.setMinutes(date.getMinutes() + (date.getTimezoneOffset() * -1))
  const fill = (number) => {
    number = `${number}`;
    return number.length === 1 ? `0${number}` : number;
  };
  const day = fill(date.getDate());
  const month = fill(date.getMonth() + 1);
  const year = date.getFullYear();
  const minute = fill(date.getMinutes());
  const hour = fill(date.getHours());
  return `${day}.${month}.${year} ${hour}:${minute}`;
};
export const getPrettyDate = (date) => {
  const fill = (number) => {
    number = `${number}`;
    return number.length === 1 ? `0${number}` : number;
  };
  const day = fill(date.getDate());
  const month = fill(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export const addMissingZero = (input) => {
  if (`${input}`.length === 1) {
    return `0${input}`;
  }
  return input;
};

export const formatFileSize = (sizeInBytes, locale = "de") => {
  return prettyBytes(sizeInBytes, { locale });
};

export const formatPrice = (priceInCents, locale = "de-DE") => {
  return currencyFormatter.format(priceInCents / 100, { locale });
};

export const getLocalURLOfFile = (file) => URL.createObjectURL(file);

export const downloadFile = (name, blobData) => {
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blobData);
  link.download = name;
  link.click();
};

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const maxFileSizeInBytes = 50000000;

export const awsLambdaURLPrefix =
  "https://4ufdumgmnk.execute-api.eu-central-1.amazonaws.com/prod/";

export const awsPresignedUploadURLLambdaPrefix = "presigned-upload-url";
export const awsSendMailLambdaPrefix = "send-mail";
