const white = "rgb(255,255,255)";
const black = "rgb(0,0,0)";
const lightgray = "rgb(226,226,226)";
const gray = "rgb(114,118,123)";
const blue = "rgb(0, 79, 139)";
const darkblue = "rgb(0,35,59)";

const theme = {
  font: {
    size: {
      sm: "14px",
      md: "18px",
      lg: "22px",
      xlg: "32px",
      headline: "60px",
    },
  },
  color: {
    font: {
      primary: gray,
      secondary: blue,
      third: white,
    },
    background: {
      primary: white,
      secondary: black,
      page: white,
      header: white,
      controlBar: white,
      controlFloat: white,
      controlHover: lightgray,
      formItemHover: lightgray,
      footer: white,
      input: lightgray,
      dropdown: lightgray,
      button: blue,
      dragAndDrop: lightgray,
      blue: blue,
      lightgray,
      footerMain: blue,
      footerSub: darkblue,
    },
    border: {
      primary: black,
      secondary: white,
      third: "rgb(210,210,210)",
    },
  },
  breakpoint: {
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
  border: {
    radius: "5px",
  },
  shadow: "rgb(219, 219, 219) 0px 5px 5px 0px",
  header: {
    height: "60px",
  },
};

const adjustedComponentStyling = {
  accordion: {
    accordion: ``,
    flex: ``,
    item: {
      item: ``,
      header: ``,
      text: ``,
      openicon: ``,
      body: ``,
    },
  },
  button: {
    button: `
    background-color: ${theme.color.background.button};
    border: none;
    transition: all 0.5s ease;
    padding: 7.5px 50px;
    border-radius: 0px;
    color: ${theme.color.font.third};
    `,
    anchor: `
    background-color: ${theme.color.background.button};
    border: none;
    transition: all 0.5s ease;
    padding: 7.5px 50px;
    border-radius: 0px;
    color: ${theme.color.font.third};
    `,
  },
  checkbox: {
    checkbox: ``,
    flex: ``,
    checkedicon: ``,
    label: ``,
    styledcheckbox: ``,
  },
  container: {
    container: ``,
  },
  draganddrop: {
    draganddrop: `
    box-sizing: border-box;
    border-radius: 0px;
    background-color: ${theme.color.background.dragAndDrop};
    height: 100%;
    border: none;
    display: flex;
  align-items: center;
  justify-content: center;
    `,
    title: `
    font-weight: bold;
    text-transform: uppercase;
    font-size: ${theme.font.size.lg};
    `,
    hint: `
    font-style: normal;
    font-size: ${theme.font.size.md};
    `,
    filelist: ``,
    filename: ``,
  },
  dropdown: {
    dropdown: ``,
    select: `
    border: none;
    background-color: ${theme.color.background.dropdown};
    `,
    option: ``,
    label: ``,
  },
  form: {
    form: ``,
  },
  headline: {
    headline: `
    text-align: left;
    font-size: ${theme.font.size.lg};
    `,
  },
  hero: {
    hero: ``,
  },
  input: {
    input: `
      background-color: ${theme.color.background.input};
      border: none;
      transition: all 0.5s ease;
      border-radius: 0px;
      font-size: ${theme.font.size.md};
      padding: 7.5px 15px;
    `,
    wrapper: ``,
    label: `
    font-size: ${theme.font.size.sm};
    `,
  },
  link: {
    link: ``,
  },
  modal: {
    modal: `
    `,
    background: `
    `,
    core: `
    `,
    header: `
    `,
    title: ``,
    close: ``,
    content: ``,
    footer: ``,
  },
  progressbar: {
    progressbar: ``,
    progress: ``,
    label: ``,
  },
  textarea: {
    textarea: `
      background-color: ${theme.color.background.input};
      border-color: ${theme.color.border.third};
      transition: all 0.5s ease;
      padding: 5px;
      &:focus {
        border-color: ${theme.color.border.primary};
      }

      ::placeholder {
        color: ${theme.color.font.secondary};
        opacity: 1;
      }
    
      :-ms-input-placeholder {
        color: ${theme.color.font.secondary};
      }
    
      ::-ms-input-placeholder {
        color: ${theme.color.font.secondary};
      }
    `,
    wrapper: ``,
    label: `
      font-size: ${theme.font.size.sm};
    `,
  },
  datepicker: {
    wrapper: ``,
    label: ``,
  },
};

const exportableTheme = { ...theme, components: adjustedComponentStyling };

export default exportableTheme;
