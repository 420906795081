import { useSelector } from "react-redux";
import { getUUID } from "../../../utility";
import {
  StyledHero,
  Subtitle,
  TitleWrapper,
  Title,
  Subline,
  Content,
} from "./Hero.Styled";
import props from "../../../redux/props";

const Hero = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledHero>
      <Content>
        <Subtitle>{translation.home.hero.subtitle}</Subtitle>
        <TitleWrapper>
          {translation.home.hero.title.map((t) => (
            <Title key={getUUID()}>{t}</Title>
          ))}
        </TitleWrapper>
        <Subline>{translation.home.hero.subline}</Subline>
      </Content>
    </StyledHero>
  );
};

export default Hero;
