import styled from "styled-components";

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.color.background.footer};
  margin-top: 200px;
`;

export const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 25px;
  }
`;

export const Item = styled.div`
  width: 100%;
  flex-grow: 0;
`;

export const Main = styled.div`
  background-color: ${({ theme }) => theme.color.background.footerMain};
  padding: 50px 0 75px 0;
`;
export const Sub = styled.div`
  background-color: ${({ theme }) => theme.color.background.footerSub};
  padding: 15px 0;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.color.font.third};
  ${({ small, theme }) => small && `font-size: ${theme.font.size.sm};`}
  text-decoration: none;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.color.font.third};
  ${({ small, theme }) => small && `font-size: ${theme.font.size.sm};`}
`;
