import { useSelector } from "react-redux";
import { StyledBoxes, Box, Title, Text } from "./Boxes.Styled";
import props from "../../../redux/props";
import { Container } from "@think-internet/ui-components";
import { getUUID } from "../../../utility";

const Boxes = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Container>
      <StyledBoxes>
        {translation.home.boxes.map((b) => (
          <Box key={getUUID()}>
            <Title>{b.title}</Title>
            <Text>{b.text}</Text>
          </Box>
        ))}
      </StyledBoxes>
    </Container>
  );
};

export default Boxes;
