import { useState } from "react";
import {
  Logo,
  StyledHeader,
  Toggle,
  Content,
  Items,
  Item,
} from "./Header.Styled";
import logo from "../../../assets/image/generic/logo.svg";
import toggle from "../../../assets/image/header/toggle.svg";
import Menu from "./Menu/Menu";
import { getUUID } from "../../../utility";
import { useSelector } from "react-redux";
import props from "../../../redux/props";

const Header = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <StyledHeader>
      <Content>
        <Logo src={logo} />
        <Items>
          {translation.header.items.map((i) => (
            <Item
              key={getUUID()}
              href={i.href}
              target={i.newTab ? "_blank" : "_self"}
            >
              {i.label}
            </Item>
          ))}
        </Items>
        <Toggle src={toggle} onClick={() => setShowMenu(!showMenu)} />
        {showMenu && <Menu />}
      </Content>
    </StyledHeader>
  );
};

export default Header;
