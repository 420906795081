import styled from "styled-components";

export const StyledContact = styled.div`
  margin-top: 200px;
`;
export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
`;

export const Split = styled.div`
  display: flex;
  gap: 75px;
  margin-top: 50px;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 50px;
  }
`;

export const PersonImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 200px;
`;

export const Detail = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size.lg};
`;

export const Title = styled.div``;
export const Subtitle = styled.div``;

export const Mail = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const Person = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || "left"};
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    justify-content: center;
  }
`;
