import styled from "styled-components";

export const StyledMenu = styled.div`
  position: fixed;
  z-index: 1000;
  top: ${({ theme }) => theme.header.height};
  left: 0;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.background.header};
  box-shadow: ${({ theme }) => theme.shadow};
  padding: 15px 15px;
  width: 100vw;
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

export const Link = styled.a`
  text-decoration: none;
  display: block;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.color.font.primary};
  }
`;
