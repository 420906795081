import { useDropzone } from "react-dropzone";
import { getUUID } from "../../../../../utility";
import {
  Title,
  Hint,
  StyledDragAndDrop,
  FileList,
  FileName,
} from "./DragAndDrop.Styled";
import toast, { TYPE } from "../../../../../toast";
import React from "react";

const DragAndDrop = ({
  title,
  hint,
  onFileCallback,
  files,
  maxFileSizeInBytes = 0,
  allowedFileExtensions,
  maxFileSizeExceededToast,
  invalidFileExtensionToast,
  multiple = true,
}) => {
  const getExtension = (filename) => filename.split(".").pop();

  const validateFile = (file) => {
    if (file.size > maxFileSizeInBytes) {
      toast(TYPE.ERROR, maxFileSizeExceededToast);
      return false;
    }

    if (
      Array.isArray(allowedFileExtensions) &&
      !allowedFileExtensions.includes(getExtension(file.name).toLowerCase())
    ) {
      toast(TYPE.ERROR, invalidFileExtensionToast);
      return false;
    }
    return true;
  };

  const onDrop = (files) => {
    const isValid = files.reduce((acc, file) => {
      if (!acc) return false;
      return validateFile(file);
    }, true);
    onFileCallback(isValid ? files : []);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple });
  // isDragActive is also a property

  if (
    !title ||
    typeof onFileCallback !== "function" ||
    !maxFileSizeInBytes ||
    !maxFileSizeExceededToast
  )
    return <></>;
  return (
    <StyledDragAndDrop {...getRootProps()}>
      <input {...getInputProps()} />
      <div>
        <Title>{title}</Title>
        {Array.isArray(hint) &&
          hint.map((h) => <Hint key={getUUID()}>{h}</Hint>)}
        {!Array.isArray(hint) && <Hint>{hint}</Hint>}
        {Array.isArray(files) && files.length > 0 && (
          <FileList>
            {files.map((file) => (
              <FileName key={getUUID()}>{file.name}</FileName>
            ))}
          </FileList>
        )}
      </div>
    </StyledDragAndDrop>
  );
};

export default DragAndDrop;
