import { useSelector } from "react-redux";
import props from "../../../../redux/props";
import { StyledMenu, Link } from "./Menu.Styled.js";
import { getUUID } from "../../../../utility";

const Menu = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledMenu>
      {translation.header.items.map((i) => (
        <Link
          key={getUUID()}
          href={i.href}
          target={i.newTab ? "_blank" : "_self"}
        >
          {i.label}
        </Link>
      ))}
    </StyledMenu>
  );
};

export default Menu;
