import styled from "styled-components";

export const StyledReasons = styled.div`
  margin-top: 0px;
`;

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.headline};
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
  font-weight: bold;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.xlg};
  }
`;
export const HeadlineNumber = styled.span`
  font-size: ${({ theme }) => theme.font.size.headline};
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
  font-weight: normal;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.xlg};
  }
`;
export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
  gap: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    gap: 15px;
  }
`;
export const Item = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  width: 30%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(50% - 15px / 2);
  }
`;
export const Number = styled.img`
  border-radius: 50px;
  height: 50px;
  width: 50px;
`;
export const Title = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  font-weight: bold;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
    text-align: center;
  }
`;
export const Text = styled.div`
  text-align: center;
`;
