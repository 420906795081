//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  header: {
    items: [
      {
        label: "Start",
        href: "https://www.wilms.eu/",
        newTab: true,
      },
      {
        label: "Fachgebiet",
        href: "https://www.wilms.eu/fachgebiete/",
        newTab: true,
      },
      {
        label: "Rechtsanwälte",
        href: "https://www.wilms.eu/rechtsanwaelte/",
        newTab: true,
      },
      {
        label: "Kanzlei",
        href: "https://www.wilms.eu/kanzlei/",
        newTab: true,
      },
      {
        label: "Karriere",
        href: "https://karriere.wilms.eu",
        newTab: true,
      },
      {
        label: "Kontakt",
        href: "https://www.wilms.eu/kontakt/",
        newTab: true,
      },
      {
        label: "Blog",
        href: "https://www.wilms.eu/blog/",
        newTab: true,
      },
    ],
  },

  home: {
    hero: {
      subtitle: "Karriere",
      title: ["Viele Menschen", "mit einem Ziel"],
      subline: "Werden Sie Teil von Wilms Rechtsanwälte",
    },
    boxes: [
      {
        title: "Team",
        text: "In unserer Kanzlei ist der Zusammenhalt von großer Bedeutung, um unsere Ziele zu erreichen und unsere Mandanten bestmöglich zu unterstützen.  Durch unsere enge Verbundenheit schaffen wir gemeinsam eine starke Grundlage für unseren Erfolg.",
      },
      {
        title: "AUSGLEICH",
        text: "Wir legen Wert auf die Balance zwischen Beruf und Privatleben. Jeder unserer Mitarbeitenden hat individuelle Bedürfnisse und Umstände, die wir respektieren und unterstützen möchten. Denn nur wenn im Privatleben ein Ausgleich gefunden wird, kann berufliches Potenzial voll entfaltet werden.",
      },
      {
        title: "Austausch",
        text: "Wir möchten unseren Mitarbeitenden helfen, sich auf vielfältige Weise weiterzuentwickeln, damit sie unsere Mandanten bestmöglich unterstützen können. Wir glauben daran, dass unterschiedliche Perspektiven und Ideen dabei helfen, eine enge Verbindung zu unseren Mandanten aufzubauen.",
      },
    ],
    reasons: {
      headline: "Gründe Teammitglied zu werden",
      items: [
        {
          title: "Modernes Arbeitsumfeld",
          text: "Bei uns erwartet Sie ein modernes Arbeitsumfeld, das mit den neuesten Technologien und Trends ausgestattet ist.",
        },
        {
          title: "Effiziente Arbeitsprozesse",
          text: "Wir setzen auf digitale Tools und Automatisierung, um unsere Arbeitsabläufe effizienter und produktiver zu gestalten.",
        },
        {
          title: "Flexibilität und Freiheit",
          text: "Wir bieten Ihnen flexible Arbeitszeiten und die Möglichkeit, eigenverantwortlich zu arbeiten. Sie können Ihre Arbeit nach Ihren individuellen Bedürfnissen gestalten.",
        },
        {
          title: "Kreativer Spirit",
          text: "Bei uns wird Kreativität großgeschrieben! Wir ermutigen Sie, neue Ideen einzubringen und unkonventionelle Lösungsansätze zu entwickeln.",
        },
        {
          title: "Beigeisterung für Technologie",
          text: "Wir sind begeistert von Technologie und immer auf dem neuesten Stand. Sie werden die Möglichkeit haben, mit modernsten Tools und Technologien zu arbeiten und Ihre Fähigkeiten weiterzuentwickeln.",
        },
        {
          title: "Gemütlichkeit am Arbeitsplatz",
          text: "Unser moderner Arbeitsplatz bietet nicht nur technische Raffinessen, sondern auch eine gemütliche Atmosphäre. Sie können sich bei uns wohlfühlen und entspannt arbeiten.",
        },
        {
          title: "Sicherheit in der Zukunft",
          text: "Durch unsere Fokussierung auf Innovation und technologischen Fortschritt bieten wir Ihnen langfristige Perspektiven und Sicherheit in einer sich wandelnden Arbeitswelt.",
        },
        {
          title: "Teamgeist und Spaß",
          text: "Bei uns steht der Teamgeist im Vordergrund. Wir organisieren regelmäßige Teamevents und schaffen eine angenehme Arbeitsatmosphäre, in der Spaß und Zusammenarbeit im Fokus stehen.",
        },
        {
          title: "Weiterbildung mit Leichtigkeit",
          text: "Wir unterstützen Ihre persönliche Weiterentwicklung und bieten Ihnen vielfältige Fortbildungsmöglichkeiten in einer lockeren und motivierenden Lernumgebung.",
        },
      ],
    },
    quote: {
      text: "„Es hat keinen Sinn zu tun, was einem gefällt - man muss Gefallen finden an dem, was man tut.”",
    },
    contact: {
      headline: "Deine Ansprechpartner",
      people: [
        {
          name: "Friedrich Schulze",
          title: "Rechtsanwalt",
          mail: "karriere@wilms.eu",
        },
        {
          name: "Kathrin Schwartz",
          title: "Rechtsanwältin",
          subtitle: "LL.M. (Informationsrecht)",
          mail: "karriere@wilms.eu",
        },
      ],
    },
    openPositions: {
      headline: "Unsere Offenene Stellen",
      tasks: "Ihre Aufgaben bei uns:",
      expectations: "Uns ist wichtig:",
      offerings: "Es erwartet Sie:",
      form: {
        entryDate: "Wunsch-Eintrittsdatum",
        salaryExpectation: "Gehaltsvorstellung",
        dragAndDropTitle: "Drag & Drop",
        dragAndDropHint: [
          "Eigene Dokumente hier hochladen",
          "(Lebenslauf, Zeugnisse, o.ä.)",
        ],
        maxFileSizeExceededToast:
          "Ihre Datei überschreitet die zulässige Größe von 50 MB.",
        cta: "Bewerbung senden",
        error: "Beim Versand Ihrer Bewerbung ist ein Fehler aufgetreten.",
        success:
          "Wir haben Ihre Bewerbung erhalten und melden uns schnellstmöglich.",
      },
      positions: [
        {
          title: "RECHTSANWALTSFACHANGESTELLTE/R (M/W/D)",
          tasks:
            "Sie erledigen die Korrespondenz mit Gerichten, Gegnern und Mandanten und sind für diese immer erster Anlaufpunkt. Zudem übernehmen Sie organisatorische Aufgaben wie die Eingangspostbearbeitung, das Fristenmanagement und die Koordinierung von Terminen.",
          expectations:
            "Wir geben Aufgaben und Richtung vor, legen aber großen Wert auf eigenständiges Arbeiten. Wir lassen Raum, eigene Ideen einzubringen und diese eigenverantwortlich umzusetzen. Sorgfältiges Arbeiten ist in unserem Beruf unverzichtbar, ebenso wie der respektvolle Umgang miteinander.",
          offerings:
            "Unser Team ist eine gute Mischung aus Jung & (Mittel)alt, es vereint jugendlichen Schwung mit ausreichend Erfahrung. Über die letzten 25 Jahre ist ein großer Mandantenstamm gewachsen, der uns zuverlässig vor immer wieder neue Aufgaben stellt. Das, was wir tun, machen wir gern. Wenn Sie mitmachen möchten, freuen wir uns auf Sie.",
        },
      ],
      success: {
        form: "Ihre Bewerbung wurde erfolgreich verschickt. Wir melden uns schnellstmöglich bei Ihnen.",
      },
      error: {
        form: "Aufgrund eines technischen Fehlers konnte Ihre Bewerbung nicht verschickt werden.",
      },
    },
  },

  footer: {
    name: "Wilms Rechtsanwälte",
    street: "Alt-Tempelhof 37/41",
    city: "12103 Berlin",
    openHours: "Montag - Freitag 8.00 - 17.00 Uhr",
    phonePrefix: "T ",
    phone: "+49 30 75 79 64 0",
    faxPrefix: "F ",
    fax: "+49 30 75 79 64 9",
    homepage: "www.wilms.eu",
    description:
      "Damit wir gemeinsam mit Ihnen die beste Lösung für Sie finden können, nehmen Sie bitte Kontakt mit uns auf und vereinbaren einen persönlichen Beratungstermin.",
  },

  imprint: {
    headline: "Impressum",
  },

  dataProtection: {
    headline: "Datenschutz",
  },

  gtc: {
    headline: "AGB",
  },

  generic: {
    firstname: "Vorname",
    lastname: "Nachname",
    mail: "Email",
    phone: "Telefon",
    password: "Passwort",
    imap: {
      server: "IMAP-Server",
      port: "IMAP-Port",
    },
    smtp: {
      server: "SMTP-Server",
      port: "SMTP-Port",
    },
    company: {
      name: "Firmenname",
      taxNumber: "USt-IdNr.",
      street: "Straße",
      postcode: "Postleitzahl",
      city: "Stadt",
      phone: "Telefon",
      website: "Webseite",
    },
  },

  login: {
    headline: "Anmelden",
    cta: "anmelden",
    error: "E-Mail Adresse oder Passwort falsch.",
    switchToSignup: "Noch kein Account? Hier registrieren.",
  },
  signUp: {
    headline: "Registrieren",
    cta: "registrieren",
    error: "Ihr Account konnte nicht registriert werden.",
    switchToLogin: "Bereits registriert? Hier einloggen.",
    personalDetail: "Persönliche Daten",
    companyDetail: "Firmendaten",
    successMessage:
      "Ihr Account wurde erfolgreich registriert. Sie erhalten in Kürze eine E-Mail mit einem Bestätigungslink.",
    activation: {
      headline: "Account aktivieren",
      success:
        "Ihr Account wurde erfolgreich aktiviert. Sie können sich nun einloggen.",
      error: "Ihr Account konnte nicht aktiviert werden.",
      switchToLogin: "Jetzt einloggen.",
    },
  },

  UI: {
    dropdown: {
      emptySelection: "Bitte wählen",
    },
  },
};

module.exports = {
  translation,
  langKey: "DE",
};
