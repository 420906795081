import { Container } from "@think-internet/ui-components";
import styled from "styled-components";

export const StyledHeader = styled.div`
  background-color: ${({ theme }) => theme.color.background.header};
  position: fixed;
  width: 100%;
  z-index: 1000;
  box-shadow: ${({ theme }) => theme.shadow};
`;

export const Content = styled(Container)`
  padding: 0 25px;
  display: flex;
  height: ${({ theme }) => theme.header.height};
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const Logo = styled.img`
  height: calc(100% - 20px);
`;

export const Toggle = styled.img`
  height: calc(100% - 20px);
  cursor: pointer;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`;

export const Item = styled.a`
  text-decoration: none;
`;
