import { StyledLayout, CoreContent } from "./Layout.Styled";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Secure from "./Secure/Secure";

const Layout = ({
  children,
  className,
  hideFooter = false,
  hideHeader = false,
  authRequired = true,
  requiredLevel = null,
  noPaddingTop = false,
}) => {
  return (
    <StyledLayout className={className}>
      <Secure authRequired={authRequired} requiredLevel={requiredLevel}>
        {!hideHeader && <Header />}
        <CoreContent noPaddingTop={noPaddingTop}>{children}</CoreContent>
        {!hideFooter && <Footer />}
      </Secure>
    </StyledLayout>
  );
};

export default Layout;
